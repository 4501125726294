import {useEffect, useState} from 'react'
import _ from 'lodash'

// Localstorage only holds strings, so we convert everything to be stored in it into JSON format.
// The state holds the Object version, and we just make sure we store it as json. We only ever
// read data out of local storage one time.

export const useLocalStorage = (localStorageKey, defaultValue) => {
  let lastSavedValue = localStorage.getItem(localStorageKey)
  const parsedLastSavedValue = lastSavedValue !== null ? _.get(JSON.parse(lastSavedValue), 'value') : defaultValue
  const [value, setValue] = useState(parsedLastSavedValue)

  useEffect(() => {
    if (value !== null) {
      localStorage.setItem(localStorageKey, JSON.stringify({value}))
    } else {
      localStorage.setItem(localStorageKey, JSON.stringify({value: defaultValue}))
    }
  }, [value])

  return [value, setValue] as const
}
