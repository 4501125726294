import * as React from 'react'
import {useState} from 'react'

const AlertContext = React.createContext(null)

const AlertProvider = ({children}) => {
  const [alerts, setAlerts] = useState([])

  const getUniqueId = () => new Date().getTime()

  const addAlert = (message, variant) => {
    const key = getUniqueId()
    setAlerts(alerts.concat({key, variant, message}))
    setTimeout(() => {
      removeAlert(key)
    }, 5000)
  }

  const removeAlert = key => {
    setAlerts(alerts.filter(e => e.key !== key))
  }
  return <AlertContext.Provider value={{alerts, addAlert, removeAlert}}>{children}</AlertContext.Provider>
}

function useAlert() {
  const context = React.useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context
}

export {AlertProvider, AlertContext, useAlert}
