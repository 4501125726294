import React from 'react'
import {Flex, FlexItem, Form, FormGroup, Switch, TextInput} from '@patternfly/react-core'
import moment from 'moment'
import _ from 'lodash'

type ISubjectMetadataProps = {
  metadata: any
  onEdit: any
  isDisabled: boolean
}

export const SubjectMetadataFormGroup: React.FunctionComponent<ISubjectMetadataProps> = ({metadata, onEdit, isDisabled}) => {
  const email_address = _.get(metadata, 'subject_metadata.email_address', '')
  const first_name = _.get(metadata, 'subject_metadata.first_name', '')
  const last_name = _.get(metadata, 'subject_metadata.last_name', '')
  const sex = _.get(metadata, 'subject_metadata.sex', '')
  const date_of_birth = _.get(metadata, 'subject_metadata.date_of_birth') ? moment(metadata.subject_metadata.date_of_birth).format('YYYY-MM-DD') : ''
  const bp_meds = _.get(metadata, 'subject_metadata.bp_meds', false)
  const smoker = _.get(metadata, 'subject_metadata.smoker', false)
  const height = _.get(metadata, 'subject_metadata.height', '')
  const weight = _.get(metadata, 'subject_metadata.weight', '')
  const fitzpatrickScale = _.get(metadata, 'subject_metadata.fitzpatrick_scale', '')

  return (
    <Form>
      <FormGroup label='Email Address' fieldId='edit-modal-email-address'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-email-address'
          name='subject_metadata.email_address'
          value={email_address}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='First Name' fieldId='edit-modal-first-name'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-first-name'
          name='subject_metadata.first_name'
          value={first_name}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='Last Name' fieldId='edit-modal-last-name'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-last-name'
          name='subject_metadata.last_name'
          value={last_name}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='Sex' isInline fieldId='edit-modal-sex'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-sex'
          name='subject_metadata.sex'
          value={sex}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='Date of Birth' isInline fieldId='edit-modal-date-of-birth'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='date'
          id='edit-modal-date-of-birth'
          name='subject_metadata.date_of_birth'
          value={date_of_birth}
          onChange={(newValue, e) => {
            // Need to blow this up a little bit because we store in unixtime but present in human-time
            onEdit({
              target: {
                name: e.target.name,
                type: e.target.type,
                value: moment(newValue, 'YYYY-MM-DD').valueOf(),
              },
            })
          }}
        />
      </FormGroup>
      <Flex>
        <FlexItem>
          <FormGroup label='Using BP medication' fieldId='edit-modal-bp-meds'>
            <Switch
              id='edit-modal-bp-meds'
              name='subject_metadata.bp_meds'
              isDisabled={isDisabled}
              label='Using BP meds'
              labelOff='Not using BP meds'
              isChecked={bp_meds}
              onChange={(newValue, e) => {
                onEdit(e)
              }}
            />
          </FormGroup>
        </FlexItem>
        <FlexItem>
          <FormGroup label='Smoker' fieldId='edit-modal-smoker'>
            <Switch
              id='edit-modal-smoker'
              name='subject_metadata.smoker'
              isDisabled={isDisabled}
              label='Smoker'
              labelOff='Non-Smoker'
              isChecked={smoker}
              onChange={(newValue, e) => {
                onEdit(e)
              }}
            />
          </FormGroup>
        </FlexItem>
      </Flex>
      <FormGroup label='Height' isInline fieldId='edit-modal-height'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='number'
          id='edit-modal-height'
          name='subject_metadata.height'
          value={height}
          onChange={(newValue, e) => {
            // Need to blow this up a little bit because we store in number but this is a string
            onEdit({
              target: {
                name: e.target.name,
                type: e.target.type,
                value: parseFloat(newValue),
              },
            })
          }}
        />
      </FormGroup>
      <FormGroup label='Weight' isInline fieldId='edit-modal-weight'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='number'
          id='edit-modal-weight'
          name='subject_metadata.weight'
          value={weight}
          onChange={(newValue, e) => {
            // Need to blow this up a little bit because we store in number but this is a string
            onEdit({
              target: {
                name: e.target.name,
                type: e.target.type,
                value: parseFloat(newValue),
              },
            })
          }}
        />
      </FormGroup>
      <FormGroup label='Fitzpatrick Scale' isInline fieldId='edit-modal-fitzpatrick-scale'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='number'
          id='edit-modal-fitzpatrick-scale'
          name='subject_metadata.fitzpatrick_scale'
          value={fitzpatrickScale}
          onChange={(newValue, e) => {
            // Need to blow this up a little bit because we store in number but this is a string
            onEdit({
              target: {
                name: e.target.name,
                type: e.target.type,
                value: parseFloat(newValue),
              },
            })
          }}
        />
      </FormGroup>
    </Form>
  )
}

export default SubjectMetadataFormGroup
