import * as React from 'react'
import {useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {
  Brand,
  Bullseye,
  Dropdown,
  DropdownGroup,
  DropdownItem,
  DropdownSeparator,
  DropdownToggle,
  Nav,
  NavItem,
  NavItemSeparator,
  NavList,
  Page,
  PageHeader,
  PageHeaderTools,
  PageSidebar,
  SkipToContent,
  Spinner,
} from '@patternfly/react-core'
import {useKeycloak} from '@react-keycloak/web'
import _ from 'lodash'
import {CaretDownIcon} from '@patternfly/react-icons'
import {useLocalStorage} from '@app/utils/useLocalStorage'

interface IAppLayout {
  children: React.ReactNode
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({children}) => {
  const logoProps = {
    href: '/',
  }
  const [isNavOpen, setIsNavOpen] = useLocalStorage('isNavOpen', true)
  const [isMobileView, setIsMobileView] = React.useState(false)
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false)
  const [userName, setUsername] = React.useState('')
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = React.useState(false)
  const {keycloak, initialized} = useKeycloak()

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile)
  }

  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen)
  }

  const onPageResize = (props: {mobileView: boolean; windowSize: number}) => {
    setIsMobileView(props.mobileView)
  }

  useEffect(() => {
    setUsername(_.get(keycloak, 'idTokenParsed.email', ''))
  }, [keycloak, initialized])

  const accountDropdownItems = [
    <DropdownGroup key='avatar menu group 1'>
      <DropdownItem key='avatar menu group 1 account management' component='div' isPlainText>
        <a href={`${process.env.VALENCELL_API_ENDPOINT}/auth/realms/Valencell/account`}>Account Management</a>
      </DropdownItem>
    </DropdownGroup>,
    <DropdownSeparator key='dropdown separator' />,
    <DropdownGroup key='avatar menu group 2'>
      <DropdownItem key='avatar menu group 2 logout'>
        <a href={`${process.env.VALENCELL_CLOUD_ENDPOINT}/logout`}>Logout</a>
      </DropdownItem>
    </DropdownGroup>,
  ]

  const accountDropdownOnToggle = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen)
  }

  const accountDropdownOnSelect = event => {
    accountDropdownOnToggle()
    const element = document.getElementById('account-toggle')
    element.focus()
  }

  const avatarHeader = (
    <PageHeaderTools>
      {userName !== '' && (
        <React.Fragment>
          <Dropdown
            isPlain
            onSelect={accountDropdownOnSelect}
            toggle={
              <DropdownToggle id='account-toggle' onToggle={accountDropdownOnToggle} toggleIndicator={CaretDownIcon}>
                {userName}
              </DropdownToggle>
            }
            isOpen={isAccountDropdownOpen}
            dropdownItems={accountDropdownItems}
          />
        </React.Fragment>
      )}
    </PageHeaderTools>
  )

  const Header = (
    <PageHeader
      logo={<Brand src={'https://3ap96f18gb4f131eh84bgi1n-wpengine.netdna-ssl.com/wp-content/uploads/2016/08/logo.png'} alt={'Valencell'} />}
      logoProps={logoProps}
      showNavToggle
      isNavOpen={isNavOpen}
      headerTools={avatarHeader}
      onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle}
    />
  )

  const Navigation = (
    <Nav id='nav-primary-simple'>
      <NavList id='nav-list-simple'>
        <NavItem key={'/experiments'}>
          <NavLink exact to={'/experiments'} activeClassName='pf-m-current'>
            Experiments
          </NavLink>
        </NavItem>
        <NavItem key={'/upload'}>
          <NavLink exact to={'/upload'} activeClassName='pf-m-current'>
            Upload
          </NavLink>
        </NavItem>
        <NavItem key={'/logout'}>
          <NavLink exact to={'/logout'} activeClassName='pf-m-current'>
            Log Out
          </NavLink>
        </NavItem>
        <NavItemSeparator />
        <NavItem key={'version'}>
          <NavLink exact to={'/experiments'} activeClassName='pf-m-current'>
            {process.env.VERSION_NUMBER}
          </NavLink>
        </NavItem>
      </NavList>
    </Nav>
  )

  const Sidebar = <PageSidebar nav={Navigation} isNavOpen={isMobileView ? isNavOpenMobile : isNavOpen} />
  const PageSkipToContent = <SkipToContent href='#primary-app-container'>Skip to Content</SkipToContent>
  const PageContent =
    initialized && keycloak.authenticated ? (
      children
    ) : (
      <Bullseye>
        <Spinner isSVG diameter={'20%'} />
      </Bullseye>
    )

  return (
    <Page mainContainerId='primary-app-container' header={Header} sidebar={Sidebar} onPageResize={onPageResize} skipToContent={PageSkipToContent}>
      {PageContent}
    </Page>
  )
}

export {AppLayout}
