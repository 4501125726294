import * as React from 'react'
import {useState} from 'react'
import {PageSection} from '@patternfly/react-core'
import {FileUpload} from '@app/Components/FileUpload/FileUpload'
import FileUploadService from '@app/services/fileUpload'
import {useAlert} from '@app/utils/AlertContext'

const Upload: React.FunctionComponent = () => {
  const [value, setValue] = useState()
  const [fileName, setFileName] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const alertContext = useAlert()
  const handleFileChange = (value, filename) => {
    setValue(value)
    setFileName(filename)

    // Value can be an empty string when the user clicks the clear button
    if (value != null || filename !== '') {
      setIsUploading(true)
      FileUploadService.upload(value, () => null)
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            if (response.data.status == 'received') {
              alertContext.addAlert('Upload successful', 'success')
            } else if (response.data.status == 'duplicate') {
              alertContext.addAlert('Duplicate file, no changes made', 'info')
            }
          }
        })
        .catch(error => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            alertContext.addAlert('Not a valid JSON bundle', 'warning')
          } else {
            alertContext.addAlert('Unable to upload file', 'danger')
          }
        })
        .then(() => {
          setIsUploading(false)
          setValue(undefined)
          setFileName('')
        })
    }
  }

  return (
    <PageSection>
      <FileUpload id='simple-file' value={value} filename={fileName} onChange={handleFileChange} isUploading={isUploading} />
    </PageSection>
  )
}

export {Upload}
