import React from 'react'

import {Page, PageSection} from '@patternfly/react-core'
import {useLocation} from 'react-router-dom'
import {SessionEditor} from '@app/Components/SessionEditor'

export const ExperimentPage: React.FunctionComponent = () => {
  const objectId = useLocation().pathname.split('/')[2]

  return (
    <Page>
      <PageSection isFilled={true} padding={{default: 'padding'}}>
        <SessionEditor objectId={objectId} />
      </PageSection>
    </Page>
  )
}

export default ExperimentPage
