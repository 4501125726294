import React, {useEffect, useState} from 'react'
import {fitContent, ICell, IExtraRowData, IRow, sortable, SortByDirection, textCenter} from '@patternfly/react-table'
import _ from 'lodash'

import {
  Chip,
  Drawer,
  DrawerActions,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentBody,
  DrawerHead,
  DrawerPanelBody,
  DrawerPanelContent,
  Flex,
  FlexItem,
  Page,
  PageSection,
  Pagination,
  PaginationVariant,
  Spinner,
  Title,
} from '@patternfly/react-core'
import {ErrorCircleOIcon, ExternalLinkAltIcon} from '@patternfly/react-icons'
import {ExperimentsTable} from '@app/Components/Experiments/ExperimentsTable'
import axios from 'axios'
import moment from 'moment'
import {useKeycloak} from '@react-keycloak/web'
import {SearchBar} from '@app/Components/Experiments/SearchBar'
import {useAlert} from '@app/utils/AlertContext'
import {useLocalStorage} from '@app/utils/useLocalStorage'
import {initiateDownload} from '@app/utils/utils'
import {SessionEditor} from '@app/Components/SessionEditor'

export interface ISearchChips {
  /** The human-friendly field name (eg: Protocol, Email Address, Experiment ID...) */
  fieldName: string
  /** The document path to search (eg: subject_metadata.email_address, session_metadata.experiment_id) */
  path?: string
  /** The search term for the given field. */
  term: string
}

export interface IDropdownLabel {
  /** The human-friendly dropdown choice name (eg: Experiment ID, Email Address) */
  title: string
  /** The document path to search (eg: subject_metadata.email_address, session_metadata.experiment_id) */
  path?: string
}

export interface IAlert {
  title: string
  variant: string
  key: string
}

export interface ISortBy {
  index: number
  direction: SortByDirection
}

export interface IExperiment {
  experiment_index: number
  selected: boolean
  client_id: string
  device_id: string
  device_key: string
  object_id: string
  firmware_revision: string
  hardware_revision: string
  led_drive_0: number
  led_drive_1: number
  led_drive_2: number
  model_number: string
  notes: string
  retest: boolean
  sample_count: number
  sample_rate: number
  sensor_id: string
  sensor_location: string
  sequencer_mode: string
  software_revision: string
  start_time: number
  type: string
}

export interface IChannel {
  client_id: string
  device_id: string
  device_key: string
  object_id: string
  firmware_revision: string
  hardware_revision: string
  led_drive_0: number
  led_drive_1: number
  led_drive_2: number
  model_number: string
  notes: string
  retest: boolean
  sample_count: number
  sample_rate: number
  selected?: boolean
  sensor_id: string
  sensor_location: string
  sequencer_mode: string
  software_revision: string
  start_time: number
  type: string
}

export interface IDataSet {
  bp_meds: boolean
  channel: IChannel[]
  date_of_birth: number
  email_address: string
  experiment_id: string
  experiment_index?: number
  height: number
  isExpanded?: boolean
  notes: string
  protocol: string
  sex: string
  smoker: boolean
  start_time: number
  weight: number
  object_id: string
  verified: boolean
  status: {
    status: string
  }
}

export const ExperimentsPage: React.FunctionComponent = () => {
  const [dataSet, setDataSet] = useState<IDataSet[]>([])
  const [page, setPage] = useState<number>(1)
  const [rowCount, setRowCount] = useState<number>(0)
  const [rows, setRows] = useState<IRow[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number>(20)
  const [sortBy, setSortBy] = useState<ISortBy>({index: 3, direction: SortByDirection.desc})
  const [startIdx, setStartIdx] = useState<number>(0)
  const [reloadCount, setReloadCount] = useState<number>(0)
  const [isSidePanelLoaded, setIsSidePanelLoaded] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [searchChips, setSearchChips] = useState<ISearchChips[]>([])
  const [metadataPanelExpanded, setMetadataPanelExpanded] = useState<boolean>(false)
  const [selectedSessionObjectId, setSelectedSessionObjectId] = useState<string | null>(null)
  const [drawerWidth, setDrawerWidth] = useLocalStorage('drawerWidth', 730) // tasteful default
  const {keycloak, initialized} = useKeycloak()
  const alertContext = useAlert()

  const [columns] = useState<ICell[]>([
    {title: 'Verified', props: {name: 'verified'}, transforms: [sortable, textCenter, fitContent]},
    {title: 'Email', props: {name: 'subject_metadata.email_address.keyword'}, transforms: [sortable]},
    {title: 'Experiment Id', props: {name: 'session_metadata.experiment_id.keyword'}, transforms: [sortable]},
    {title: 'Start Time', props: {name: 'session_metadata.start_time'}, transforms: [sortable]},
    {title: 'Protocol', props: {name: 'session_metadata.protocol.keyword'}, transforms: [sortable]},
    {title: 'Sex', props: {name: 'subject_metadata.sex.keyword'}, transforms: [sortable, fitContent]},
    {title: 'Height', props: {name: 'subject_metadata.height'}, transforms: [sortable, fitContent]},
    {title: 'Weight', props: {name: 'subject_metadata.weight'}, transforms: [sortable, fitContent]},
    {title: 'DOB', props: {name: 'subject_metadata.date_of_birth'}, transforms: [sortable, fitContent]},
    {title: 'BP Meds', props: {name: 'subject_metadata.bp_meds'}, transforms: [sortable, fitContent]},
    {title: ''}, // Empty space for the action kebab
  ])

  const handleSelectItem = (e, row) => {
    setSelectedSessionObjectId(row.objectId)

    // don't open side panel if user clicked a button (e.g. kebob)
    if (_.get(e, ['target', 'type']) !== 'button') {
      setMetadataPanelExpanded(true)
      setIsSidePanelLoaded(false)
    }
  }

  const handleSidePanelOnClose = () => {
    setMetadataPanelExpanded(false)
  }

  const download = async (fileName, objectId, format) => {
    try {
      const result = await axios(`${process.env.VALENCELL_API_ENDPOINT}/renderer/v1/${format}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
        responseType: 'blob',
        data: objectId,
        method: 'post',
      })
      if (result.status === 200) {
        initiateDownload(result.data, fileName)
      } else {
        alertContext.addAlert('Unable to fetch file', 'warning')
      }
    } catch (e) {
      if (e.response.status === 404) {
        alertContext.addAlert('File not found, please try again later', 'warning')
      } else {
        alertContext.addAlert('Unable to convert file', 'warning')
      }
    }
  }

  const actionResolver = (rowData, {rowIndex}: IExtraRowData) => {
    const downloadOptions = [
      {title: 'Download Legacy Format', format: 'legacy'},
      {title: 'Download JSON-L Bundle', format: 'jsonlzip'},
    ]

    if (_.get(dataSet[rowIndex], 'pbd', false)) {
      downloadOptions.push({title: 'Download PBD Bundle', format: 'ingress'})
    }

    return downloadOptions.map(downloadOption => {
      const getStartTimeText = () => {
        const startTime = dataSet[rowIndex]?.start_time
        if (startTime == undefined) {
          return 'undefined'
        } else {
          return `${moment.unix(startTime / 1000).format('YYYY-MM-DD, hh_mm_ss')}`
        }
      }

      const fileFormatText = () => {
        if (downloadOption.format === 'legacy') {
          return 'Legacy'
        } else if (downloadOption.format === 'jsonlzip') {
          return 'JSON-L'
        } else if (downloadOption.format === 'ingress') {
          return 'PBD'
        } else {
          return 'Unknown Format'
        }
      }

      const listOfDutsText = () => {
        const dutListText = dataSet[rowIndex]['channel']
          .map(channel => {
            return channel.device_id
          })
          .join('-')
        if (dutListText.includes('USBISS')) {
          // Don't bother showing anything for USB devices
          return ''
        }
        return `(${dutListText})`
      }

      return {
        title: downloadOption.title,
        onClick: () => {
          const fileName = `${fileFormatText()} ${getStartTimeText()} ${listOfDutsText()} - ${dataSet[rowIndex].email_address.trim()} ${dataSet[rowIndex].protocol.trim()}.zip`
          const objectId = {object_id: dataSet[rowIndex]['channel'][0]['object_id']}
          download(fileName, objectId, downloadOption.format)
        },
      }
    })
  }

  // This will keep triggering the search results to refresh by uselessly incrementing a number every so often
  useEffect(() => {
    setTimeout(() => {
      setReloadCount(prevCount => prevCount + 1)
    }, 5000)
  }, [reloadCount])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(`${process.env.VALENCELL_API_ENDPOINT}/search/v1/experiment`, {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json',
          },
          data: {
            from: startIdx,
            size: rowsPerPage,
            searchTerms: searchChips,
            sort: {field: columns[sortBy.index].props.name, direction: sortBy.direction},
          },
          method: 'post',
        })

        if (result.status === 200) {
          setRowCount(result.data.hits)
          setDataSet(result.data.experiments)
        } else {
          //TODO: Fix these alertContext warnings
          alertContext.addAlert(`${result.status} Error fetching experiments`, 'warning')
        }
      } catch (e) {
        alertContext.addAlert('Unable to communicate with cloud', 'warning')
      }
    }
    if (initialized && keycloak.authenticated) {
      fetchData()
    }
  }, [searchChips, sortBy, page, rowsPerPage, reloadCount])

  useEffect(() => {
    // If we change search terms or sort ordering, reset to page 1 result 1
    setPage(1)
    setStartIdx(0)
  }, [searchChips, sortBy])

  useEffect(() => {
    const newRows: IRow[] = []

    dataSet.forEach(row => {
      const status = _.get(row, ['status', 'status'])
      let style = {}
      if (row.object_id === selectedSessionObjectId) {
        // Highlight row grey when clicked, and blue when the metadata was loaded into the sidepanel
        if (isSidePanelLoaded) {
          style = {
            borderBottom: '2px solid var(--pf-global--primary-color--100)',
            borderTop: '2px solid var(--pf-global--primary-color--100)',
          }
        } else {
          style = {
            borderBottom: '2px solid var(--pf-global--secondary-color--100)',
            borderTop: '2px solid var(--pf-global--secondary-color--100)',
          }
        }
      }

      const statusIcons = () => {
        let statusIcon
        switch (status) {
          case 'ready':
            statusIcon = <React.Fragment />
            break
          case 'error':
            statusIcon = <ErrorCircleOIcon style={{verticalAlign: '-0.125em', marginLeft: '0.125em'}} title={status} />
            break
          default:
            statusIcon = <Spinner size={'md'} style={{verticalAlign: '-0.125em', marginLeft: '0.125em'}} title={status} />
        }

        return (
          <React.Fragment>
            {row.verified ? <Chip isReadOnly>Verified</Chip> : <Chip isReadOnly>Not Verified</Chip>}
            {statusIcon}
          </React.Fragment>
        )
      }

      const record = {
        isOpen: false,
        objectId: row.object_id,
        status: _.get(row, ['status', 'status'], false),
        cells: [
          {title: statusIcons(), props: {errorText: '', style}},
          {title: row.email_address, props: {errorText: '', style}},
          {title: row.experiment_id, props: {style}},
          {title: moment(row.start_time).format('YYYY-MM-DD HH:mm:ss'), props: {style}},
          {title: row.protocol, props: {style}},
          {title: row.sex, props: {style}},
          {title: row.height.toFixed(2), props: {style}},
          {title: row.weight.toFixed(2), props: {style}},
          {title: moment(row.date_of_birth).format('YYYY-MM-DD'), props: {style}},
          {title: row.bp_meds ? 'True' : 'False', props: {style}},
          {title: '', props: {style}, actionProps: {style}}, // Empty space for the action kebab
        ],
      }
      newRows.push(record)
    })

    setRows(newRows)
  }, [dataSet, searchString, selectedSessionObjectId, isSidePanelLoaded])

  const areActionsDisabled = rowData => {
    return rowData.status != 'ready'
  }

  const onSort = (_event, index, direction) => {
    setSortBy({
      index,
      direction,
    })
  }

  const getSelectedRowStatus = () => {
    const selectedRow = dataSet.filter(row => {
      return row.object_id === selectedSessionObjectId
    })[0]

    return _.get(selectedRow, ['status', 'status'], false)
  }

  const pagination = variant => (
    <Pagination
      itemCount={rowCount}
      perPage={rowsPerPage}
      page={page}
      onSetPage={(e, newPage, perPage, startIdx) => {
        setPage(newPage)
        setStartIdx(startIdx ? startIdx : 0)
      }}
      perPageOptions={[
        {title: '10', value: 10},
        {title: '20', value: 20},
        {title: '50', value: 50},
        {title: '100', value: 100},
      ]}
      onPerPageSelect={(e, newPerPage, newPage, startIdx) => {
        setRowsPerPage(newPerPage)
        setStartIdx(startIdx ? startIdx : 0)
      }}
      variant={variant}
      isCompact
    />
  )

  return (
    <Page>
      <PageSection isFilled={true} padding={{default: 'noPadding'}}>
        <Drawer isExpanded={metadataPanelExpanded} isInline={true}>
          <DrawerContent
            className={'side-panel'}
            panelContent={
              <DrawerPanelContent
                isResizable
                onResize={e => {
                  setDrawerWidth(e)
                }}
                id='right-resize-panel'
                defaultSize={`${drawerWidth}px`}>
                <DrawerHead>
                  <DrawerActions>
                    <DrawerCloseButton onClick={handleSidePanelOnClose} />
                  </DrawerActions>
                  <Flex>
                    <FlexItem>
                      <Title size='lg' headingLevel='h2'>
                        Metadata
                      </Title>
                    </FlexItem>
                    <FlexItem>
                      <a href={`../experiment/${selectedSessionObjectId}`}>
                        <ExternalLinkAltIcon />
                      </a>
                    </FlexItem>
                  </Flex>
                </DrawerHead>
                <DrawerPanelBody>
                  <SessionEditor objectId={selectedSessionObjectId} />
                </DrawerPanelBody>
              </DrawerPanelContent>
            }>
            <DrawerContentBody>
              <Flex>
                <FlexItem grow={{default: 'grow'}}>
                  <SearchBar searchChips={searchChips} setSearchChips={setSearchChips} searchString={searchString} setSearchString={setSearchString} />
                </FlexItem>
                <FlexItem>{pagination(PaginationVariant.top)}</FlexItem>
              </Flex>

              <PageSection isFilled={true} padding={{default: 'noPadding'}}>
                <ExperimentsTable
                  columns={columns}
                  rows={rows}
                  handleRowClick={handleSelectItem}
                  sortBy={sortBy}
                  onSort={onSort}
                  selectedSessionId={selectedSessionObjectId}
                  actionResolver={actionResolver}
                  areActionsDisabled={areActionsDisabled}
                />
              </PageSection>
              {pagination(PaginationVariant.bottom)}
            </DrawerContentBody>
          </DrawerContent>
        </Drawer>
      </PageSection>
    </Page>
  )
}

export default ExperimentsPage
