import {Button, Flex, FlexItem, FormGroup, Switch} from '@patternfly/react-core'
import {useSessionEditor} from '@app/Components/SessionEditor/SessionEditorContext'
import React from 'react'
import * as jsonpatch from 'fast-json-patch'
import axios from 'axios'
import {useKeycloak} from '@react-keycloak/web'
import {useAlert} from '@app/utils/AlertContext'
import _ from 'lodash'

export const SessionEditorPersistentForm: React.FunctionComponent = () => {
  const sessionEditor = useSessionEditor()
  const alertContext = useAlert()
  const {keycloak} = useKeycloak()

  const handleSave = value => {
    // "Grey out" the form immediately, even before the auto-refresh data comes in
    sessionEditor.setIsDisabled(true)

    const patch = jsonpatch.compare(sessionEditor.originalSessionMetadata, sessionEditor.selectedSessionMetadata)

    axios(`${process.env.VALENCELL_API_ENDPOINT}/ingestion/v1/session`, {
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: {
        object_id: sessionEditor.selectedSessionMetadata.object_id,
        patch,
      },
      method: 'patch',
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          alertContext.addAlert('Session metadata updating...', 'info')
        }
      })
      .catch(error => {
        if (error.response.status >= 400 && error.response.status < 500) {
          const message = _.get(error, ['response', 'data', 'error'], error.response.data)
          alertContext.addAlert(`Unable to update metadata: ${message}`, 'danger')
        } else {
          alertContext.addAlert('Unable to update metadata', 'danger')
        }
      })
  }

  // Flip a state variable to kick off a change in useEffect and cause the metadata to be re-pulled from the api
  const handleDiscard = () => {
    sessionEditor.loadSession(sessionEditor.selectedSessionMetadata.object_id)
  }

  return (
    <FormGroup fieldId='edit-modal-verified'>
      <Flex direction={{default: 'column'}}>
        <FlexItem>
          <Switch
            id='edit-modal-verified'
            name='verified'
            label='Verified'
            labelOff='Not Verified'
            isChecked={_.get(sessionEditor, 'selectedSessionMetadata.verified', false)}
            onChange={(newValue, e) => {
              sessionEditor.handleOnEdit({target: e.currentTarget})
            }}
          />
        </FlexItem>
        <FlexItem>
          <Flex>
            <FlexItem>
              <Button isDisabled={!sessionEditor.isMetadataDirty} onClick={handleSave}>
                Save
              </Button>
            </FlexItem>
            <FlexItem>
              <Button isDisabled={!sessionEditor.isMetadataDirty} variant={'secondary'} onClick={handleDiscard}>
                Discard
              </Button>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </FormGroup>
  )
}
