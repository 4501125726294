import * as React from 'react'
import {Alert, AlertActionCloseButton, AlertGroup, AlertVariant} from '@patternfly/react-core'
import {AlertContext} from '@app/utils/AlertContext'

export const AlertToaster: React.FunctionComponent = () => {
  return (
    <AlertGroup isToast>
      <AlertContext.Consumer>
        {({alerts, addAlert, removeAlert}) => {
          return alerts.map(({key, variant, message}) => (
            <Alert
              isLiveRegion
              variant={AlertVariant[variant]}
              title={message}
              actionClose={<AlertActionCloseButton title={message} variantLabel={`${variant} alert`} onClose={() => removeAlert(key)} />}
              key={key}
            />
          ))
        }}
      </AlertContext.Consumer>
    </AlertGroup>
  )
}
