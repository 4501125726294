import * as React from 'react'
import '@patternfly/react-core/dist/styles/base.css'
import {BrowserRouter as Router} from 'react-router-dom'
import {AppLayout} from '@app/Pages/AppLayout/AppLayout'
import {AppRoutes} from '@app/routes'
import {ReactKeycloakProvider} from '@react-keycloak/web'
import '@app/app.css'
import Keycloak from 'keycloak-js'

const keycloak = Keycloak({
  realm: 'Valencell',
  clientId: 'cloud-console',
  url: `${process.env.KEYCLOAK_ENDPOINT}/auth`,
})

const keycloakProviderInitOptions = {
  onLoad: 'login-required',
}

const App: React.FunctionComponent = () => (
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitOptions}>
    <Router>
      <AppLayout>
        <AppRoutes />
      </AppLayout>
    </Router>
  </ReactKeycloakProvider>
)

export {App}
