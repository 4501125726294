import React, {useState} from 'react'
import {Card, CardBody, Chip, ChipGroup, Dropdown, DropdownItem, DropdownToggle, InputGroup, TextInput} from '@patternfly/react-core'
import {IDropdownLabel, ISearchChips} from '@app/Pages/Experiments/ExperimentsPage'
import {CaretDownIcon, SearchIcon} from '@patternfly/react-icons'
import {Button} from '@patternfly/react-core/dist/js/components/Button/Button'

type ISearchBarProps = {
  searchString: string
  setSearchString: (string) => void
  searchChips: ISearchChips[]
  setSearchChips: (searchChips: ISearchChips[]) => void
}

export const SearchBar: React.FunctionComponent<ISearchBarProps> = ({searchString, setSearchString, searchChips, setSearchChips}) => {
  const [searchDropdownIsOpen, setSearchDropdownIsOpen] = useState<boolean>(false)
  const [searchDropdownLabel, setSearchDropdownLabel] = useState<IDropdownLabel>({
    title: 'Search Field',
    path: undefined,
  })
  const [searchIsEnabled, setSearchIsEnabled] = useState<boolean>(false)
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>('Select a search field')

  const dropdownItems = [
    <DropdownItem
      key='experimentId'
      component='button'
      onClick={() => {
        setSearchPlaceholder('Search by Experiment Id')
        setSearchDropdownLabel({title: 'Experiment ID', path: 'session_metadata.experiment_id'})
        setSearchIsEnabled(true)
      }}>
      Experiment ID
    </DropdownItem>,
    <DropdownItem
      key='protocol'
      component='button'
      onClick={() => {
        setSearchPlaceholder('Search by Protocol')
        setSearchDropdownLabel({title: 'Protocol', path: 'session_metadata.protocol'})
        setSearchIsEnabled(true)
      }}>
      Protocol
    </DropdownItem>,
    <DropdownItem
      key='emailAddress'
      component='button'
      onClick={() => {
        setSearchPlaceholder('Search by Email Address')
        setSearchDropdownLabel({title: 'Email Address', path: 'subject_metadata.email_address'})
        setSearchIsEnabled(true)
      }}>
      Email Address
    </DropdownItem>,
  ]

  const dropdownOnToggle = () => {
    setSearchDropdownIsOpen(!searchDropdownIsOpen)
  }

  const dropdownFocus = () => {
    const element = document.getElementById('search-dropdown-id')
    if (element != null) {
      element.focus()
    }
  }

  const dropdownOnSelect = () => {
    setSearchDropdownIsOpen(!searchDropdownIsOpen)
    dropdownFocus()
  }

  const searchSubmit = () => {
    setSearchChips([
      ...searchChips,
      {
        fieldName: searchDropdownLabel.title,
        path: searchDropdownLabel.path,
        term: searchString,
      },
    ])
    setSearchString('')
  }

  const handleSearchbarKeyDown = event => {
    if (event.key === 'Enter') {
      searchSubmit()
    }
  }

  const chipsSection = () => {
    if (searchChips.length > 0) {
      const categories: string[] = []

      searchChips.forEach(value => {
        if (!categories.includes(value.fieldName)) {
          categories.push(value.fieldName)
        }
      })

      const getTerms = (categoryName: string) => {
        const chips = searchChips.filter(e => {
          return e.fieldName === categoryName
        })

        return chips.map(chip => {
          return (
            <Chip
              key={`${categoryName}:${chip.term}`}
              onClick={() => {
                searchChips.find(obj => {
                  if (obj.path === chip.path && obj.term === chip.term) {
                    const newSearchChips = searchChips.filter(function (e) {
                      return e.path !== chip.path && e.term !== chip.term
                    })
                    setSearchChips(newSearchChips)
                  }
                })
              }}>
              {chip.term}
            </Chip>
          )
        })
      }

      return categories.map(categoryName => {
        return (
          <ChipGroup key={categoryName} categoryName={categoryName}>
            {getTerms(categoryName)}
          </ChipGroup>
        )
      })
    } else {
      return null
    }
  }

  return (
    <Card>
      <CardBody>
        <InputGroup>
          <Dropdown
            onSelect={dropdownOnSelect}
            toggle={
              <DropdownToggle id='search-dropdown-id' onToggle={dropdownOnToggle} toggleIndicator={CaretDownIcon}>
                {searchDropdownLabel.title}
              </DropdownToggle>
            }
            isOpen={searchDropdownIsOpen}
            dropdownItems={dropdownItems}
          />
          <TextInput
            name='search-input'
            id='search-input'
            type='search'
            aria-label='search term text input field'
            placeholder={searchPlaceholder}
            isDisabled={!searchIsEnabled}
            value={searchString}
            onKeyDown={handleSearchbarKeyDown}
            onChange={value => {
              if (searchIsEnabled) {
                setSearchString(value)
              }
            }}
          />
          <Button
            variant='control'
            aria-label='submit search query button'
            onClick={() => {
              searchSubmit()
            }}>
            <SearchIcon />
          </Button>
        </InputGroup>
        {chipsSection()}
      </CardBody>
    </Card>
  )
}
