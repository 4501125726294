import {ChartZoom, defaultAxisOptions, resolveOptions} from '../../../../node_modules/timechart/dist/lib/chartZoom'

export class TimeChartZoom {
  constructor(chart, options, setxDomain, setyRange) {
    this.options = options
    this.setxDomain = setxDomain
    this.setyRange = setyRange
    this.registerZoom(chart, setxDomain, setyRange)
  }

  applyAutoRange(o, dataRange) {
    if (!o) return
    if (!o.autoRange) {
      delete o.minDomain
      delete o.maxDomain
      return
    }
    let [min, max] = o.scale.domain()
    if (dataRange) {
      min = Math.min(min, dataRange.min)
      max = Math.max(max, dataRange.max)
    }
    o.minDomain = min
    o.maxDomain = max
  }

  registerZoom(chart, setxDomain, setyRange) {
    const newZoomChart = new ChartZoom(chart.contentBoxDetector.node, {
      x: this.options.x && Object.assign(Object.create(this.options.x), {scale: chart.model.xScale}),
      y: this.options.y && Object.assign(Object.create(this.options.y), {scale: chart.model.yScale}),
    })
    const newZoomChartOptions = newZoomChart.options
    // This locks the Y axis, so the user can't pan the chart to clip beyond the top or bottom of the screen
    chart.model.updated.on(() => {
      this.applyAutoRange(newZoomChartOptions.x, chart.model.xRange)
      this.applyAutoRange(newZoomChartOptions.y, chart.model.yRange)
      newZoomChart.update()
    })

    newZoomChart.onScaleUpdated(() => {
      chart.options.xRange = null
      chart.options.yRange = null
      chart.options.realTime = false
      const domain = chart.model.xScale.domain()
      setxDomain({min: domain[0], max: domain[1]})

      const range = chart.model.yScale.domain()
      setyRange({min: range[0], max: range[1]})
      chart.update()
    })
  }
}

const defaults = Object.assign(Object.create(defaultAxisOptions), {
  autoRange: true,
})

export class SharedZoomChartPlugin {
  constructor(o, setxDomain, setyRange) {
    this.options = resolveOptions(defaults, o)
    this.setxDomain = setxDomain
    this.setyRange = setyRange
  }

  apply(chart) {
    return new TimeChartZoom(chart, this.options, this.setxDomain, this.setyRange)
  }
}
