import {Banner, Modal} from '@patternfly/react-core'
import React from 'react'
import {useSessionEditor} from './SessionEditorContext'

type PPGPlotProps = {
  isOpen: boolean
  onClose: () => void
}

const PPGPlot: React.FunctionComponent<PPGPlotProps> = ({isOpen, onClose}) => {
  const {selectedDeviceId, ppgChartDiv, accelChartDiv} = useSessionEditor()

  return (
    <Modal title={`${selectedDeviceId} Data Stream`} isOpen={isOpen} onClose={onClose}>
      <span>
        <div style={{width: '90vw', height: '35vh'}} ref={ppgChartDiv} />
        <div style={{width: '90vw', height: '35vh'}} ref={accelChartDiv} />
        <Banner>
          <b>Scroll/Drag</b> to pan.
        </Banner>
        <Banner>
          <b>Ctrl+Scroll</b> to zoom X axis.
        </Banner>
        <Banner>
          <b>Ctrl+Alt+Scroll</b> to zoom Y axis.
        </Banner>
      </span>
    </Modal>
  )
}

export default PPGPlot
