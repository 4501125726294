import {Form, Menu, MenuContent, MenuItem, MenuList, Tab, Tabs, TabTitleText} from '@patternfly/react-core'
import BPMetadataFormGroup from '@app/Components/SessionEditor/BPMetadataFormGroup'
import DeviceMetadata from '@app/Components/SessionEditor/DeviceMetadata'
import ReactJson from 'react-json-view'
import React, {useState} from 'react'
import SessionMetadataFormGroup from '@app/Components/SessionEditor/SessionMetadataFormGroup'
import {useSessionEditor} from '@app/Components/SessionEditor/SessionEditorContext'
import SubjectMetadataFormGroup from '@app/Components/SessionEditor/SubjectMetadataFormGroup'
import axios from 'axios'
import {initiateDownload} from '@app/utils/utils'
import {CodeBranchIcon, CubeIcon, LayerGroupIcon} from '@patternfly/react-icons'
import _ from 'lodash'
import {useAlert} from '@app/utils/AlertContext'
import {useKeycloak} from '@react-keycloak/web'
import moment from 'moment'

export type SessionEditorTabsProps = {disabled: boolean; objectId: string}

export const SessionEditorTabs: React.FunctionComponent<SessionEditorTabsProps> = ({disabled, objectId}) => {
  const [activeDrawerTabKey, setActiveDrawerTabKey] = useState<string>('session-tab')
  const sessionEditor = useSessionEditor()
  const alertContext = useAlert()
  const {keycloak} = useKeycloak()

  const handleDrawerTabClick = (e, tabIndex) => {
    setActiveDrawerTabKey(tabIndex)
  }

  const download = async format => {
    const fileFormatText = () => {
      if (format === 'legacy') {
        return 'Legacy'
      } else if (format === 'jsonlzip') {
        return 'JSON-L'
      } else if (format === 'ingress') {
        return 'PBD'
      } else {
        return 'Unknown Format'
      }
    }

    const sessionTimestampText = `${moment.unix(sessionEditor.selectedSessionMetadata.session_metadata.start_time / 1000).format('YYYY-MM-DD, hh_mm_ss')}`

    const listOfDutsText = () => {
      const dutListText = Object.keys(sessionEditor.selectedSessionMetadata.source.dut).join('-')
      if (dutListText.includes('USBISS')) {
        // Don't bother showing anything for USB devices
        return ''
      }
      return `(${dutListText})`
    }

    const fileName = `${fileFormatText()} ${sessionTimestampText} ${listOfDutsText()} - ${sessionEditor.selectedSessionMetadata.subject_metadata.email_address.trim()} ${sessionEditor.selectedSessionMetadata.session_metadata.protocol.trim()}.zip`

    const request = {object_id: objectId}
    try {
      const result = await axios(`${process.env.VALENCELL_API_ENDPOINT}/renderer/v1/${format}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
        responseType: 'blob',
        data: request,
        method: 'post',
      })
      if (result.status === 200) {
        initiateDownload(result.data, fileName)
      } else {
        alertContext.addAlert('Unable to fetch file', 'warning')
      }
    } catch (e) {
      if (e.response.status === 404) {
        alertContext.addAlert('File not found, please try again later', 'warning')
      } else {
        alertContext.addAlert('Unable to convert file', 'warning')
      }
    }
  }

  const downloadButtons = () => {
    return (
      <MenuContent>
        <MenuList>
          <MenuItem icon={<CodeBranchIcon aria-hidden />} itemId={'legacy'}>
            Legacy
          </MenuItem>
          <MenuItem icon={<LayerGroupIcon aria-hidden />} itemId={'jsonlzip'}>
            JSON-L
          </MenuItem>
          {_.get(sessionEditor.selectedSessionMetadata, 'sourceFileTypes', new Set()).has('pbd') && (
            <MenuItem icon={<CubeIcon aria-hidden />} itemId={'ingress'}>
              PBD
            </MenuItem>
          )}
        </MenuList>
      </MenuContent>
    )
  }

  return (
    <Tabs activeKey={activeDrawerTabKey} onSelect={handleDrawerTabClick}>
      <Tab eventKey={'session-tab'} title={<TabTitleText>Session</TabTitleText>}>
        <SessionMetadataFormGroup metadata={sessionEditor.selectedSessionMetadata} isDisabled={disabled} onEdit={sessionEditor.handleOnEdit} />
      </Tab>
      <Tab eventKey={'subject-tab'} title={<TabTitleText>Subject</TabTitleText>}>
        <SubjectMetadataFormGroup metadata={sessionEditor.selectedSessionMetadata} isDisabled={disabled} onEdit={sessionEditor.handleOnEdit} />
      </Tab>
      <Tab eventKey={'bp-tab'} title={<TabTitleText>BP</TabTitleText>}>
        <BPMetadataFormGroup metadata={sessionEditor.selectedSessionMetadata} isDisabled={disabled} onEdit={sessionEditor.handleOnEdit} />
      </Tab>
      <Tab eventKey={'devices-tab'} title={<TabTitleText>Devices</TabTitleText>}>
        <DeviceMetadata metadata={sessionEditor.selectedSessionMetadata} />
      </Tab>
      <Tab eventKey={'bp-json'} title={<TabTitleText>JSON</TabTitleText>}>
        <Form>
          <ReactJson
            src={sessionEditor.selectedSessionMetadata ? sessionEditor.selectedSessionMetadata : {status: 'loading'}}
            collapsed={1}
            enableClipboard={false}
            displayDataTypes={false}
          />
        </Form>
      </Tab>
      <Tab eventKey={'downloads'} title={<TabTitleText>Download</TabTitleText>}>
        <Form>
          <Menu
            onSelect={(e, itemId) => {
              const fileFormat = itemId // Hackish, I know
              download(fileFormat)
            }}>
            {downloadButtons()}
          </Menu>
        </Form>
      </Tab>
    </Tabs>
  )
}
