import React from 'react'
import {Form, FormGroup, TextArea, TextInput} from '@patternfly/react-core'
import moment from 'moment'
import _ from 'lodash'

type ISessionMetadataProps = {
  metadata: any
  onEdit: any
  isDisabled: boolean
}

export const SessionMetadataFormGroup: React.FunctionComponent<ISessionMetadataProps> = ({metadata, onEdit, isDisabled}) => {
  const protocol = _.get(metadata, 'session_metadata.protocol', '')
  const experiment_id = _.get(metadata, 'session_metadata.experiment_id', '')
  const start_time = _.get(metadata, 'session_metadata.start_time') ? moment(metadata.session_metadata.start_time).format('YYYY-MM-DDTHH:mm:ss') : ''
  const notes = _.get(metadata, 'session_metadata.notes', '')

  return (
    <Form>
      <FormGroup label='Protocol' fieldId='edit-modal-protocol'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-protocol'
          name='session_metadata.protocol'
          value={protocol}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='Experiment Id' fieldId='edit-modal-experiment-id'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='text'
          id='edit-modal-experiment-id'
          name='session_metadata.experiment_id'
          value={experiment_id}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
      <FormGroup label='Start Time' isInline fieldId='edit-modal-start-time'>
        <TextInput
          isRequired
          isDisabled={isDisabled}
          type='datetime-local'
          id='edit-modal-start-time'
          name='session_metadata.start_time'
          value={start_time}
          onChange={(newValue, e) => {
            // Need to blow this up a little bit because we store in unixtime but present in human-time
            onEdit({
              target: {
                name: e.currentTarget.name,
                type: e.currentTarget.type,
                value: moment(newValue, 'YYYY-MM-DDTHH:mm:ss').valueOf(),
              },
            })
          }}
        />
      </FormGroup>
      <FormGroup label='Notes' fieldId='edit-modal-notes'>
        <TextArea
          type='text'
          id='edit-modal-notes'
          name='session_metadata.notes'
          autoResize
          isDisabled={isDisabled}
          rows={5}
          value={notes}
          onChange={(newValue, e) => {
            onEdit(e)
          }}
        />
      </FormGroup>
    </Form>
  )
}

export default SessionMetadataFormGroup
