import * as React from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {Spinner} from '@patternfly/react-core'

const Logout: React.FunctionComponent = () => {
  const {keycloak, initialized} = useKeycloak()
  if (initialized && keycloak.authenticated) {
    keycloak.logout({redirectUri: process.env.VALENCELL_CLOUD_ENDPOINT})
  }
  return <Spinner />
}

export {Logout}
