import {applyCellEdits, cancelCellEdits, EditableSelectInputCell, IRow, Table, TableBody, TableHeader, TableVariant, validateCellEdits} from '@patternfly/react-table'
import React from 'react'
import {Card, CardBody, Form, SelectOption} from '@patternfly/react-core'
import moment from 'moment'
import _ from 'lodash'
import '@patternfly/react-core/dist/styles/base.css'
import {Button} from '@patternfly/react-core/dist/js/components/Button/Button'
import {PlusCircleIcon} from '@patternfly/react-icons'
import {EditableCell} from '@app/Components/Table/EditableCell'

type IBPMetadataProps = {
  metadata
  onEdit
  isDisabled: boolean
}

type IBPMeasurement = {
  systolic: number
  diastolic: number
}

type ICuffMeasurement = {
  timestamp: number
  bpMeasurement: IBPMeasurement
  device_id: string
  device_type: string
  lineNumber: number
}

// JSON-schema keys and prettified human strings to pair it with
const cuffTypes = [
  {type: 'auto_cuff', name: 'Auto'},
  {type: 'manual_cuff', name: 'Manual'},
]

export default class BPMetadataFormGroup extends React.Component<IBPMetadataProps> {
  private readonly columns: {title: string}[]
  private readonly actions: {onClick: (event, rowId, rowData) => void; title: string}[]

  constructor(props) {
    super(props)

    this.state = {
      rows: [],
    }

    this.columns = [{title: 'Timestamp'}, {title: 'Source'}, {title: 'Systolic'}, {title: 'Diastolic'}]
    this.actions = [
      {
        title: 'Delete',
        onClick: (event, rowId, rowData) => {
          const newRows = Array.from(this.state.rows)
          newRows[rowData.initialRowIndex] = null
          this.setState({rows: newRows})
          this.props.onEdit({
            target: {
              name: `source.${rowData.deviceType}.${rowData.deviceId}.series.${rowData.lineNumber}`,
              value: null,
            },
          })
        },
      },
    ]
  }

  updateEditableRows = (evt, eventType, isEditable, rowIndex, validationErrors) => {
    const newRows = Array.from(this.state.rows)

    /**
     Signify "deletes" in the this.state.rows array by replacing the elements to be deleted with nulls, so the
     this.state.rows array indices remain the same
     (eg: nulling index 2 won't shift indices 3, 4, 5 and so on to the left whereas slicing an array would)

     PatternFly's Table can't render nulls for rows, so the table is passed a filtered array not containing nulls.
     This shifts the indices of the PatternFly table, so the PatternFly table's rowIndex cannot be used to access the
     corresponding row in this.state.rows (as t.s.r has more elements in it, some of which are nulls)

     Only before any changes are made to a freshly loaded bundle, will the PatternFly table's rowIndex correspond 1:1 to
     this.state.rows. This information is preserved as the initialRowIndex in each row.

     We update this.state.rows's array by indexing off the row's initialRowIndex, not the PatternFly table's rowIndex
     */
    const filteredRows = newRows.filter(row => row != null) // filteredRows = the rows that PatternFly's table sees
    const rowBeingEdited = filteredRows[rowIndex]

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowBeingEdited.initialRowIndex] = validateCellEdits(rowBeingEdited, eventType, validationErrors)
      this.setState({rows: newRows})
      return
    }

    if (eventType === 'cancel') {
      newRows[rowBeingEdited.initialRowIndex] = cancelCellEdits(rowBeingEdited)
      this.setState({rows: newRows})
      return
    }

    newRows[rowBeingEdited.initialRowIndex] = applyCellEdits(rowBeingEdited, eventType)
    this.setState({rows: newRows})

    // Persist to metadata when edit is applied, not as they're typing
    if (rowBeingEdited.isEditable == false) {
      const {lineNumber, deviceType, deviceId} = rowBeingEdited

      this.props.onEdit({
        target: {
          lineNumber,
          value: rowBeingEdited.cells[0].props.value,
          name: `source.${deviceType}.${deviceId}.series.${lineNumber}.timestamp`,
          type: 'datetime-local',
        },
      })

      this.props.onEdit({
        target: {
          lineNumber,
          value: rowBeingEdited.cells[2].props.value,
          name: `source.${deviceType}.${deviceId}.series.${lineNumber}.BP_MEASUREMENT.systolic`,
          type: 'number',
        },
      })

      this.props.onEdit({
        target: {
          lineNumber,
          value: rowBeingEdited.cells[3].props.value,
          name: `source.${deviceType}.${deviceId}.series.${lineNumber}.BP_MEASUREMENT.diastolic`,
          type: 'number',
        },
      })
    }
  }

  handleTextInputChange = (newValue, evt, rowIndex, cellIndex) => {
    const newRows = Array.from(this.state.rows)
    const filteredRows = newRows.filter(row => row != null)
    const rowBeingEdited = filteredRows[rowIndex]

    if (evt.target.type == 'date') {
      newRows[rowBeingEdited.initialRowIndex].cells[cellIndex].props.editableValue = moment(newValue).format('YYYY-MM-DD')
    } else if (evt.target.type == 'datetime-local') {
      newRows[rowBeingEdited.initialRowIndex].cells[cellIndex].props.editableValue = moment(newValue).format('YYYY-MM-DD HH:mm:ss')
    } else {
      newRows[rowBeingEdited.initialRowIndex].cells[cellIndex].props.editableValue = newValue
    }
    this.setState({
      rows: newRows,
    })
  }

  onSelect = (newValue, evt, rowIndex, cellIndex, isPlaceholder) => {
    const newRows = Array.from(this.state.rows)
    const filteredRows = newRows.filter(row => row != null)
    const rowBeingEdited = filteredRows[rowIndex]
    const {initialRowIndex} = rowBeingEdited
    const newCellProps = newRows[initialRowIndex].cells[cellIndex].props

    if (isPlaceholder) {
      newCellProps.editableValue = []
      newCellProps.selected = []
    } else {
      if (newCellProps.editableValue === undefined) {
        newCellProps.editableValue = []
      }

      let newSelected = Array.from(newCellProps.selected)

      switch (newCellProps.editableSelectProps.variant) {
        case 'typeaheadmulti':
        case 'checkbox': {
          if (!newSelected.includes(newValue)) {
            newSelected.push(newValue)
          } else {
            newSelected = newSelected.filter(el => el !== newValue)
          }
          break
        }
        default: {
          newSelected = newValue
        }
      }

      newCellProps.editableValue = newSelected
      newCellProps.selected = newSelected
      const cuffType = cuffTypes.find(e => e.name == newValue).type
      const endIndexOfMatchingCuffType = newRows.filter(e => e != null && e.deviceType == cuffType).length
      let existingLineNumber = newRows[initialRowIndex].lineNumber
      existingLineNumber = existingLineNumber != null ? existingLineNumber : endIndexOfMatchingCuffType

      this.props.onEdit({
        target: {
          value: null,
          name: `source.${newRows[initialRowIndex].deviceType}.${newRows[initialRowIndex].deviceId}.series.${existingLineNumber}`,
        },
      })

      newRows[initialRowIndex].deviceType = cuffType
      newRows[initialRowIndex].lineNumber = endIndexOfMatchingCuffType
    }

    this.setState({
      rows: newRows,
    })
  }

  clearSelection = (rowIndex, cellIndex) => {
    const newRows = Array.from(this.state.rows)
    const newCellProps = newRows[rowIndex].cells[cellIndex].props
    newCellProps.editableValue = []
    newCellProps.selected = []
    this.setState({
      rows: newRows,
    })
  }

  onToggle = (isOpen, rowIndex, cellIndex, initialRowIndex) => {
    const newRows = Array.from(this.state.rows)
    newRows[initialRowIndex].cells[cellIndex].props.isSelectOpen = isOpen
    this.setState({
      rows: newRows,
    })
  }

  getLineNumber = () => {
    return this.state.rows.filter(row => row != null && row.deviceType === 'manual_cuff').length
  }

  handleAddEntry = () => {
    const newRows = Array.from(this.state.rows)
    const initialRowIndex = newRows.length
    newRows.push({
      rowEditBtnAriaLabel: idx => `Edit row ${idx}`,
      rowSaveBtnAriaLabel: idx => `Save edits for row ${idx}`,
      rowCancelBtnAriaLabel: idx => `Cancel edits for row ${idx}`,
      isEditable: true,
      deviceType: 'manual_cuff',
      deviceId: '0',
      lineNumber: this.getLineNumber(),
      initialRowIndex,
      rowEditValidationRules: [
        {
          name: 'required',
          validator: value => {
            return value.trim() !== ''
          },
          errorText: 'This field is required',
        },
      ],
      cells: [
        {
          title: (value, rowIndex, cellIndex, props) => (
            <EditableCell
              value={value}
              rowIndex={rowIndex}
              cellIndex={cellIndex}
              props={props}
              type={'datetime-local'}
              handleTextInputChange={this.handleTextInputChange}
              inputAriaLabel='New Row Timestamp'
            />
          ),
          props: {
            value: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          title: (value, rowIndex, cellIndex, props) => {
            return (
              <EditableSelectInputCell
                value={value}
                rowIndex={rowIndex}
                cellIndex={cellIndex}
                editableSelectProps={{placeHolderText: 'Placeholder'}}
                props={props}
                onSelect={this.onSelect}
                isOpen={props.isSelectOpen}
                options={props.options.map((option, index) => (
                  <SelectOption key={index} value={option.value} id={`CuffTypeOption${index}`} isPlaceholder={option.isPlaceholder} />
                ))}
                onToggle={isOpen => {
                  this.onToggle(isOpen, rowIndex, cellIndex, initialRowIndex)
                }}
                selections={props.selected}
              />
            )
          },

          props: {
            value: ['auto_cuff'],
            isSelectOpen: this.props.isSelectOpen || false,
            selected: this.props.selected || [''],
            options: [
              {value: 'Auto', name: 'auto_cuff'},
              {value: 'Manual', name: 'manual_cuff'},
            ],
            editableSelectProps: {
              'variant': 'single',
              'aria-label': 'New Row Cuff Type Dropdown',
            },
          },
        },
        {
          title: (value, rowIndex, cellIndex, props) => (
            <EditableCell
              value={value}
              rowIndex={rowIndex}
              cellIndex={cellIndex}
              props={props}
              type={'number'}
              handleTextInputChange={this.handleTextInputChange}
              inputAriaLabel='New Row Systolic Measurement'
            />
          ),
          props: {
            value: '',
          },
        },
        {
          title: (value, rowIndex, cellIndex, props) => (
            <EditableCell
              value={value}
              rowIndex={rowIndex}
              cellIndex={cellIndex}
              props={props}
              type={'number'}
              handleTextInputChange={this.handleTextInputChange}
              inputAriaLabel='New Row Diastolic Measurement'
            />
          ),
          props: {
            value: '',
          },
        },
      ],
    })
    this.setState({rows: newRows})
  }

  getRowsFromMetadata() {
    const newRows: IRow[] = []
    let initialRowIndex = 0
    // Iterate over each device of each type
    cuffTypes.forEach(cuffType => {
      Object.keys(_.get(this.props.metadata, ['source', cuffType.type], {})).map(cuff_id => {
        const cuff = this.props.metadata.source[cuffType.type][cuff_id]

        // Generate an array of PatternFly Table rows based off what's in the jsonl
        cuff.series?.forEach((cuffMeasurement, lineNumber) => {
          newRows.push({
            rowEditBtnAriaLabel: idx => `Edit row ${idx}`,
            rowSaveBtnAriaLabel: idx => `Save edits for row ${idx}`,
            rowCancelBtnAriaLabel: idx => `Cancel edits for row ${idx}`,
            deviceType: cuffType.type,
            deviceId: cuff_id,
            lineNumber,
            initialRowIndex,
            cells: [
              {
                title: (value, rowIndex, cellIndex, props) => (
                  <EditableCell
                    value={moment(value).format('YYYY-MM-DD HH:mm:ss')}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                    props={props}
                    type={'datetime-local'}
                    handleTextInputChange={this.handleTextInputChange}
                    inputAriaLabel='Timestamp'
                  />
                ),
                props: {
                  value: cuffMeasurement.timestamp,
                  name: `timestamp`,
                },
              },
              {
                title: (value, rowIndex, cellIndex, props) => (
                  <EditableSelectInputCell
                    value={value}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                    editableSelectProps={{placeHolderText: 'Placeholder'}}
                    props={props}
                    onSelect={this.onSelect}
                    isOpen={props.isSelectOpen}
                    options={props.options.map((option, index) => (
                      <SelectOption key={index} value={option.value} id={index} isPlaceholder={option.isPlaceholder} />
                    ))}
                    onToggle={isOpen => {
                      this.onToggle(isOpen, rowIndex, cellIndex, initialRowIndex)
                    }}
                    selections={props.selected}
                  />
                ),

                props: {
                  value: [cuffType.name],
                  name: `deviceType`,
                  isSelectOpen: this.props.isSelectOpen || false,
                  selected: this.props.selected || [''],
                  options: [
                    {value: 'Auto', name: 'auto_cuff'},
                    {value: 'Manual', name: 'manual_cuff'},
                  ],
                  editableSelectProps: {
                    'variant': 'single',
                    'aria-label': 'Cuff Type',
                  },
                },
              },
              {
                title: (value, rowIndex, cellIndex, props) => (
                  <EditableCell
                    value={value}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                    props={props}
                    type={'number'}
                    e
                    handleTextInputChange={this.handleTextInputChange}
                    inputAriaLabel='Systolic Blood Pressure'
                  />
                ),
                props: {
                  value: cuffMeasurement.BP_MEASUREMENT.systolic,
                  name: `systolic`,
                },
              },
              {
                title: (value, rowIndex, cellIndex, props) => (
                  <EditableCell
                    value={value}
                    rowIndex={rowIndex}
                    cellIndex={cellIndex}
                    props={props}
                    type={'number'}
                    handleTextInputChange={this.handleTextInputChange}
                    inputAriaLabel='Diastolic Blood Pressure'
                  />
                ),
                props: {
                  value: cuffMeasurement.BP_MEASUREMENT.diastolic,
                  name: `diastolic`,
                },
              },
            ],
          })
          initialRowIndex++
        })
      })
    })

    this.setState({rows: newRows})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata?.object_id != this.props.metadata?.object_id) {
      this.getRowsFromMetadata()
    }
  }

  render() {
    const {rows} = this.state
    const filteredRows = rows?.filter(row => {
      return row !== null
    })
    if (filteredRows.length > 0) {
      return (
        <Form>
          <Table
            aria-label='BP Table'
            actions={this.actions}
            areActionsDisabled={(rowData, extraData) => {
              return this.props.isDisabled
            }}
            variant={TableVariant.compact}
            cells={this.columns}
            rows={filteredRows}
            onRowEdit={this.props.isDisabled ? undefined : this.updateEditableRows}>
            <TableHeader />
            <TableBody />
          </Table>
          <Button variant='link' isDisabled={this.props.isDisabled} icon={<PlusCircleIcon />} onClick={this.handleAddEntry}>
            Add Entry
          </Button>
        </Form>
      )
    } else {
      return (
        <Card>
          <CardBody>No BP measurements Found</CardBody>
        </Card>
      )
    }
  }
}
