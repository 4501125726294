import {Banner, Bullseye, Modal} from '@patternfly/react-core'
import React from 'react'
import {useSessionEditor} from './SessionEditorContext'
import {Button} from '@patternfly/react-core/dist/js/components/Button/Button'

type ConnectionHealthPlotProps = {
  isOpen: boolean
  onClose: () => void
}

const ConnectionHealthPlot: React.FunctionComponent<ConnectionHealthPlotProps> = ({isOpen, onClose}) => {
  const {connectionHealthPlotURL, selectedDeviceId, setIsConnectionHealthPlotOpen} = useSessionEditor()

  return (
    <Modal
      title={`${selectedDeviceId} Connection Health`}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button key='confirm' variant='primary' onClick={() => setIsConnectionHealthPlotOpen(false)}>
          OK
        </Button>,
      ]}>
      <Bullseye>
        <img
          src={connectionHealthPlotURL}
          alt={'Device Connection Health Plot'}
          onError={e => {
            setConnectionHealthPlotError(true)
          }}
          style={{maxHeight: '75vh'}}
        />
      </Bullseye>
    </Modal>
  )
}

export default ConnectionHealthPlot
