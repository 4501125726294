export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById('primary-app-container')
    if (mainContainer) {
      mainContainer.focus()
    }
  }, 50)
}

export function any(iterable) {
  for (let index = 0; index < iterable.length; index++) {
    if (iterable[index]) return true
  }
  return false
}

// The downloader fetches an endpoint then stores the contents in 'result',
// then create a hidden anchor tag that links to that result and sends a mouse click to it to initiate the
// file save-as dialogue. This won't open a new tab or redirect the user away from the page they're on.
// This was driven because there isn't a "download file" javascript function that I'm aware of.
export const initiateDownload = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  if (link.parentNode != null) {
    link.parentNode.removeChild(link)
  }
}
