import * as React from 'react'
import {LastLocationProvider, useLastLocation} from 'react-router-last-location'
import {NotFound} from '@app/Pages/NotFound/NotFound'
import {Redirect, Route, RouteComponentProps, Switch, useParams} from 'react-router-dom'
import {accessibleRouteChangeHandler} from '@app/utils/utils'
import {useDocumentTitle} from '@app/utils/useDocumentTitle'
import {Logout} from '@app/Pages/Logout/Logout'
import ExperimentsPage from '@app/Pages/Experiments/ExperimentsPage'
import {Upload} from '@app/Pages/Upload/Upload'
import {EuiErrorBoundary} from '@elastic/eui'
import {AlertToaster} from '@app/Components/Util/AlertToaster'
import {AlertProvider} from '@app/utils/AlertContext'
import ExperimentPage from "@app/Pages/Experiment";

let routeFocusTimer: number

export interface IAppRoute {
  label?: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean
  path: string
  title: string
  isAsync?: boolean
  requiredRole?: string
}

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
const useA11yRouteChange = (isAsync: boolean) => {
  const lastNavigation = useLastLocation()
  React.useEffect(() => {
    if (!isAsync && lastNavigation != null) {
      routeFocusTimer = accessibleRouteChangeHandler()
    }
    return () => {
      window.clearTimeout(routeFocusTimer)
    }
  }, [isAsync, lastNavigation])
}

const RouteWithTitleUpdates = ({component: Component, isAsync = false, title, ...rest}: IAppRoute) => {
  useA11yRouteChange(isAsync)
  useDocumentTitle(title)

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />
  }

  return <Route render={routeWithTitle} />
}

const PageNotFound = ({title}: {title: string}) => {
  useDocumentTitle(title)
  return <Route component={NotFound} />
}

const AppRoutes = () => {
  return (
    <LastLocationProvider>
      <EuiErrorBoundary>
        <AlertProvider>
          <AlertToaster />
          <Switch>
            <Route exact path='/' key={'/'}>
              <Redirect to='/experiments' />
            </Route>
            <RouteWithTitleUpdates path={'/experiments'} key={'/experiments'} title={'Experiments'} component={ExperimentsPage}/>
            <RouteWithTitleUpdates path={'/experiment/:o'} key={'/experiment'} title={'Experiment'} component={ExperimentPage} />
            <RouteWithTitleUpdates path={'/upload'} key={'/upload'} title={'Upload'} component={Upload} />
            <RouteWithTitleUpdates path={'/logout'} key={'/logout'} title={'Log Out'} component={Logout} />
            <PageNotFound title='404 Page Not Found' />
          </Switch>
        </AlertProvider>
      </EuiErrorBoundary>
    </LastLocationProvider>
  )
}

export {AppRoutes}
