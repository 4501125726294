import {Table, TableBody, TableHeader, TableVariant} from '@patternfly/react-table'
import React from 'react'

export interface ExperimentsTableProps {
  columns
  rows
  sortBy
  onSort
  selectedSessionId
  handleRowClick
  actionResolver
  areActionsDisabled
}

export class ExperimentsTable extends React.Component<ExperimentsTableProps> {
  constructor(props: ExperimentsTableProps) {
    super(props)
  }

  render() {
    return (
      <Table
        aria-label='sessions table'
        variant={TableVariant.compact}
        rows={this.props.rows}
        cells={this.props.columns}
        sortBy={this.props.sortBy}
        onSort={this.props.onSort}
        actionResolver={this.props.actionResolver}
        areActionsDisabled={this.props.areActionsDisabled}>
        <TableHeader />
        <TableBody onRowClick={this.props.handleRowClick} />
      </Table>
    )
  }
}
