import React, {createContext} from 'react'

export interface SessionEditorContextType {
  eventDataRef: any
  ppgDataRef: any
  accelXDataRef: any
  accelYDataRef: any
  accelZDataRef: any
  connectionHealthPlotURL: string | null
  connectionHealthPlotLoaded: boolean
  connectionHealthPlotError: boolean
  isConnectionHealthPlotOpen: boolean
  setIsConnectionHealthPlotOpen: (isConnectionHealthPlotOpen: boolean) => void
  handleOnEdit: (target: any) => void
  isDisabled: boolean
  isDownloaded: boolean
  isMetadataDirty: boolean
  isDataStreamPlotEnabled: boolean
  isDataStreamPlotReady: boolean
  isDataStreamPlotOpen: boolean
  originalSessionMetadata: any
  selectedDeviceId: string
  selectedSessionMetadata: any
  setConnectionHealthPlotURL: (connectionHealthPlotURL: string) => void
  setConnectionHealthPlotLoaded: (connectionHealthPlotLoaded: boolean) => void
  setConnectionHealthPlotError: (connectionHealthPloterror: boolean) => void
  setIsDisabled: (isDisabled: boolean) => void
  setIsDownloaded: (isDownloaded: boolean) => void
  setIsMetadataDirty: (isMetadataDirty: boolean) => void
  setIsDataStreamPlotEnabled: (isDataStreamPlotEnabled: boolean) => void
  setIsDataStreamPlotReady: (isDataStreamPlotReady: boolean) => void
  setIsDataStreamPlotOpen: (isDataStreamPlotOpen: boolean) => void
  setOriginalSessionMetadata: (originalSessionMetadata: any) => void
  setSelectedDeviceId: (selectedDeviceId: string) => void
  setSelectedSessionMetadata: (selectedSessionMetadata: any) => void
  loadSession: (objectId: string) => void
  setXDomain: (range: {min: number; max: number}) => void
  setPPGYRange: (range: {min: number; max: number}) => void
  setAccelYRange: (range: {min: number; max: number}) => void
  xDomain: {min: number; max: number}
  ppgYRange: {min: number; max: number}
  accelYRange: {min: number; max: number}
  ppgChart: null
  accelChart: null
  ppgChartDiv: null
  accelChartDiv: null
}

const SessionEditorContext = createContext<SessionEditorContextType>({
  eventDataRef: null,
  ppgDataRef: null,
  connectionHealthPlotURL: null,
  connectionHealthPlotLoaded: false,
  connectionHealthPlotError: false,
  isConnectionHealthPlotOpen: false,
  setIsConnectionHealthPlotOpen: () => null,
  accelXDataRef: null,
  accelYDataRef: null,
  accelZDataRef: null,
  handleOnEdit: () => null,
  isDisabled: false,
  isDownloaded: false,
  isMetadataDirty: false,
  isDataStreamPlotEnabled: false,
  isDataStreamPlotReady: false,
  isDataStreamPlotOpen: false,
  originalSessionMetadata: {},
  selectedDeviceId: '',
  selectedSessionMetadata: {},
  setConnectionHealthPlotURL: () => null,
  setConnectionHealthPlotLoaded: () => null,
  setConnectionHealthPlotError: () => null,
  setIsDisabled: () => null,
  setIsDownloaded: () => null,
  setIsMetadataDirty: () => null,
  setIsDataStreamPlotEnabled: () => null,
  setIsDataStreamPlotReady: () => null,
  setIsDataStreamPlotOpen: () => null,
  setOriginalSessionMetadata: () => null,
  setSelectedDeviceId: () => null,
  setSelectedSessionMetadata: () => null,
  loadSession: () => null,
  setXDomain: () => null,
  setPPGYRange: () => null,
  setAccelYRange: () => null,
  xDomain: {min: 0, max: 0},
  ppgYRange: {min: 0, max: 0},
  accelYRange: {min: 0, max: 0},
  ppgChart: null,
  accelChart: null,
  ppgChartDiv: null,
  accelChartDiv: null,
})

SessionEditorContext.displayName = 'SessionEditorContext'

export function useSessionEditor() {
  const context = React.useContext(SessionEditorContext)
  if (context === undefined) {
    throw new Error('useSessionEditor must be used within a SessionEditorProvider')
  }
  return context
}

export const SessionEditorProvider = SessionEditorContext.Provider
