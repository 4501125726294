import {Divider} from '@patternfly/react-core'
import React, {useEffect, useReducer, useRef, useState} from 'react'
import axios from 'axios'
import _ from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import {useAlert} from '@app/utils/AlertContext'
import JSZip from 'jszip'
import {scaleLinear} from 'd3-scale'
import {SessionEditorContextType, SessionEditorProvider} from '@app/Components/SessionEditor/SessionEditorContext'
import {SessionEditorTabs} from '@app/Components/SessionEditor/SessionEditorTabs'
import {SessionEditorPersistentForm} from '@app/Components/SessionEditor/SessionEditorPersistentForm'
import produce from 'immer'
import moment from 'moment'
import PPGPlot from '@app/Components/SessionEditor/PPGPlot'
import Promise from 'promise'
import {EventsPlugin} from '../../../../node_modules/timechart/dist/lib/plugins_extra'
import {SharedZoomChartPlugin} from '@app/Components/SessionEditor/SharedZoomChartPlugin'
import TimeChart from 'timechart'
import ConnectionHealthPlot from '@app/Components/SessionEditor/ConnectionHealthPlot'

export type SessionEditorProps = {
  objectId: string | null
}

type EventPoint = {
  x: number
  name: string
}

type PPGPoint = {
  x: number
  y: number
}

export const SessionEditor: React.FunctionComponent<SessionEditorProps> = ({objectId}) => {
  const [isConnectionHealthPlotOpen, setIsConnectionHealthPlotOpen] = useState(false)
  const [isDataStreamPlotEnabled, setIsDataStreamPlotEnabled] = useState(false)
  const [isDataStreamPlotOpen, setIsDataStreamPlotOpen] = useState(false)
  const [isDataStreamPlotReady, setIsDataStreamPlotReady] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [isMetadataDirty, setIsMetadataDirty] = useState(false)
  const [accelYRange, setAccelYRange] = useState('auto')
  const [connectionHealthPlotError, setConnectionHealthPlotError] = useState(false)
  const [connectionHealthPlotLoaded, setConnectionHealthPlotLoaded] = useState(false)
  const [connectionHealthPlotURL, setConnectionHealthPlotURL] = useState(null)
  const [originalSessionMetadata, setOriginalSessionMetadata] = useState({})
  const [ppgYRange, setPPGYRange] = useState('auto')
  const [selectedDeviceId, setSelectedDeviceId] = useState()
  const [xDomain, setXDomain] = useState('auto')
  const accelXDataRef = useRef<PPGPoint[]>([])
  const accelYDataRef = useRef<PPGPoint[]>([])
  const accelZDataRef = useRef<PPGPoint[]>([])
  const eventDataRef = useRef<EventPoint[]>([])
  const ps1DataRef = useRef<PPGPoint[]>([])
  const ps2DataRef = useRef<PPGPoint[]>([])
  const ps3DataRef = useRef<PPGPoint[]>([])
  const ppgChartDiv = useRef<HTMLDivElement>(null)
  const accelChartDiv = useRef<HTMLDivElement>(null)
  const ppgChart = useRef<TimeChart>(null)
  const accelChart = useRef<TimeChart>(null)
  const [selectedSessionMetadata, setSelectedSessionMetadata] = useReducer(metadataReducer, null)
  const alertContext = useAlert()
  const {initialized, keycloak} = useKeycloak()

  // Because the metadata object is so complex, we use a reducer to update its values
  function metadataReducer(state, updateArg) {
    // If _reset arg is passed in, torch the state and start fresh
    if (_.get(updateArg, '_reset', false)) {
      return null
    }

    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (_.has(updateArg, '_path') && _.has(updateArg, '_value')) {
      const {_path, _value} = updateArg
      /* produce comes from Immer - https://immerjs.github.io/immer/
       * The basic idea is that you will apply all your changes to a temporary draftState, which is a proxy of the
       * currentState. Once all your mutations are completed, Immer will produce the nextState based on the mutations
       * to the draft state. This means that you can interact with your data by simply modifying it while keeping all
       * the benefits of immutable data.
       */
      return produce(state, draft => {
        _.set(draft, _path, _value)
      })
    } else if (_.has(updateArg, '_path') && _.has(updateArg, '_delete')) {
      const {_path} = updateArg
      return produce(state, draft => {
        _.omit(draft, [_path.join('.')])
      })
    } else {
      return {...state, ...updateArg}
    }
  }

  const handleOnEdit = ({target: {value, name, type}}) => {
    setIsMetadataDirty(true)
    const updatePath = name.split('.')

    // if the input is a checkbox then use toggle state based on previous state
    if (type === 'checkbox') {
      // If the value like 'verified' or 'uses_bp_meds' was never set, it defaults to false
      const prevValue = _.get(selectedSessionMetadata, updatePath, false)
      setSelectedSessionMetadata({_path: updatePath, _value: !prevValue})
      // Sometimes it's YYYY-MM-DD and others it's a unix timestamp. Just accept both
    } else if (type === 'datetime-local') {
      const timeValue = value.toString().includes('-') ? moment(value, 'YYYY-MM-DDTHH:mm:ss').valueOf() : value
      setSelectedSessionMetadata({_path: updatePath, _value: timeValue})
    } else if (type === 'date') {
      const timeValue = value.toString().includes('-') ? moment(value, 'YYYY-MM-DD').valueOf() : value
      setSelectedSessionMetadata({_path: updatePath, _value: timeValue})
    } else if (type === 'number') {
      setSelectedSessionMetadata({_path: updatePath, _value: parseInt(value)})
    } else if (type === 'delete') {
      setSelectedSessionMetadata({_path: updatePath, _delete: true})
    } else {
      // Otherwise, just set the value to the contents of the input field
      setSelectedSessionMetadata({_path: updatePath, _value: value})
    }
  }

  const retrieveMetadata = bundle => {
    return bundle
      .file('index.json')
      .async('string')
      .then(
        payload => {
          const index = JSON.parse(payload)
          // We're going to create multiple promises, one for each jsonl file.
          // We want to wait for all of them to complete before updating state
          const promises: any[] = []
          for (const source_type of Object.keys(index.source)) {
            for (const device_id of Object.keys(index.source[source_type])) {
              if ('series' in index.source[source_type][device_id]) {
                const source_jsonl_file = index.source[source_type][device_id]['series'].replace('\\', '/')
                index.source[source_type][device_id].series = []
                promises.push(
                  bundle
                    .file(source_jsonl_file)
                    .async('string')
                    .then(jsonl_content => {
                      const jsonlines = jsonl_content.split(/\r?\n/)
                      jsonlines.forEach(jsonLine => {
                        // In case there's an empty at the end of the file, stop parsing
                        if (jsonLine.trim() == '') {
                          return
                        }
                        const row_json = JSON.parse(jsonLine)
                        index.source[source_type][device_id].series.push(row_json)
                      })
                    })
                )
              }
            }
          }
          // Spike into the selectedSessionMetadata some extra values that are in ES but not the
          // actual JSON bundle.
          index['object_id'] = objectId

          // Check if we contain a PBD file, and if we do - show the extra option on the downloads tab
          index.sourceFileTypes = new Set()

          for (const [sourceType, value] of Object.entries(index.source)) {
            for (const [source, value] of Object.entries(_.get(index, `source.${sourceType}`))) {
              index.sourceFileTypes.add(_.get(index, `source.${sourceType}.${source}.type`))
            }
          }

          // Wait for all the promises to resolve
          Promise.all(promises).then(() => {
            setOriginalSessionMetadata(index)
            setSelectedSessionMetadata(index)
            setIsDisabled(false)
            setIsMetadataDirty(false)
          })
        },
        error => {
          return Promise.reject(error)
        }
      )
  }

  /**
   * Extracts the PPG and event data from a bundle that has datastream
   * @param bundle
   */
  const retrieveSeriesData = bundle => {
    bundle
      .file('index.json')
      .async('string')
      .then(payload => {
        const index = JSON.parse(payload)
        // We're going to create multiple promises, one for each jsonl file.
        // We want to wait for all of them to complete before updating state
        const promises: any[] = []
        for (const sourceType of Object.keys(index.source)) {
          for (const thisDeviceId of Object.keys(index.source[sourceType])) {
            if (thisDeviceId == selectedDeviceId) {
              if ('series' in index.source[sourceType][selectedDeviceId]) {
                const sourceJsonlFile = index.source[sourceType][selectedDeviceId]['series'].replace('\\', '/')
                index.source[sourceType][selectedDeviceId].series = []
                ps1DataRef.current = []
                ps2DataRef.current = []
                ps3DataRef.current = []
                accelXDataRef.current = []
                accelYDataRef.current = []
                accelZDataRef.current = []
                eventDataRef.current = []
                let currentTime = 0
                const sampleRate = _.get(index, ['source', sourceType, selectedDeviceId, 'initial_config', 'sample_rate'])
                promises.push(
                  bundle
                    .file(sourceJsonlFile)
                    .async('string')
                    .then(jsonlContent => {
                      const jsonlines = jsonlContent.split(/\r?\n/)
                      jsonlines.forEach((jsonLine, lineNo) => {
                        // In case there's an empty line at the end of the file, stop parsing
                        if (jsonLine.trim() == '') {
                          return
                        }
                        const parsedJsonLine = JSON.parse(jsonLine)
                        currentTime = currentTime + 1 / sampleRate

                        const eventValue = _.get(parsedJsonLine, 'datastream.EVENT_ID')
                        const ps1value = _.get(parsedJsonLine, `datastream.PS1`)
                        const ps2value = _.get(parsedJsonLine, `datastream.PS2`)
                        const ps3value = _.get(parsedJsonLine, `datastream.PS3`)

                        const accelValue = {
                          X: _.get(parsedJsonLine, 'datastream.X'),
                          Y: _.get(parsedJsonLine, 'datastream.Y'),
                          Z: _.get(parsedJsonLine, 'datastream.Z'),
                        }

                        if (eventValue !== undefined) {
                          eventDataRef.current.push({x: currentTime, name: 'Event'})
                        }
                        if (ps1value !== undefined) {
                          ps1DataRef.current.push({x: currentTime, y: ps1value})
                        }
                        if (ps2value !== undefined) {
                          ps2DataRef.current.push({x: currentTime, y: ps2value})
                        }
                        if (ps3value !== undefined) {
                          ps3DataRef.current.push({x: currentTime, y: ps3value})
                        }
                        if (accelValue.X !== undefined) {
                          accelXDataRef.current.push({x: currentTime, y: accelValue.X})
                          accelYDataRef.current.push({x: currentTime, y: accelValue.Y})
                          accelZDataRef.current.push({x: currentTime, y: accelValue.Z})
                        }
                      })
                      setIsDataStreamPlotReady(true)
                    })
                )
              }
            }
          }
        }
        // Spike into the selectedSessionMetadata some extra values that are in ES but not the
        // actual JSON bundle.
        index['object_id'] = objectId

        // Wait for all the promises to resolve
        Promise.all(promises).then(() => {
          return Promise.resolve()
        })
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  const fetchConnectionHealthPlot = (object_id, device_id) => {
    setConnectionHealthPlotLoaded(false)
    setConnectionHealthPlotError(false)

    if (object_id == null || device_id == null) return
    axios(`${process.env.VALENCELL_API_ENDPOINT}/renderer/v1/_device_connection_health/${object_id}/${device_id}.svg`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
      responseType: 'blob',
      method: 'get',
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.resolve(response.data)
        } else {
          setConnectionHealthPlotError(true)
          setConnectionHealthPlotLoaded(true)
          return Promise.reject(new Error(response.statusText))
        }
      })
      .then(plot => {
        setConnectionHealthPlotURL(URL.createObjectURL(plot))
        setConnectionHealthPlotError(false)
        setConnectionHealthPlotLoaded(true)
      })
      .catch(e => {
        setConnectionHealthPlotError(true)
        setConnectionHealthPlotLoaded(true)
      })
  }

  /**
   * Returns a promise that will contain a zip compressed JSON bundle
   */
  const fetchBundle = (object_id, sources_to_include: string[] = []) => {
    const zip = new JSZip()
    let zipContent: Promise<Blob> | Promise<never>

    setIsDataStreamPlotEnabled(true)
    setIsDownloaded(false)
    setIsDataStreamPlotReady(false)

    return axios(`${process.env.VALENCELL_API_ENDPOINT}/renderer/v1/metadata`, {
      headers: {Authorization: `Bearer ${keycloak.token}`},
      responseType: 'blob',
      data: {object_id, source: sources_to_include},
      method: 'post',
    })
      .then(response => {
        zipContent = Promise.resolve(response.data)
        setIsDownloaded(true)
        return zip.loadAsync(zipContent)
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 404) {
            return Promise.reject({message: 'File processing, please try again later', severity: 'warning'})
          } else {
            return Promise.reject({message: 'Unable to fetch metadata', severity: 'warning'})
          }
        } else {
          return Promise.reject({
            message: `Error making request: ${_.get(error, 'message', 'Unknown Error')}`,
            severity: 'warning',
          })
        }
      })
  }

  const resetDataStreamChart = () => {
    setPPGYRange('auto')
    setAccelYRange('auto')
    setXDomain('auto')
    if (ppgChart.current?.dispose != undefined) {
      ppgChart.current.dispose()
    }
    if (accelChart.current?.dispose != undefined) {
      accelChart.current.dispose()
    }
  }

  const loadSession = objectId => {
    if (objectId != null && initialized && keycloak.authenticated) {
      setIsDisabled(true)
      setIsDataStreamPlotReady(false)
      setIsDownloaded(false)
      setSelectedSessionMetadata({_reset: true}) // Hack to force a new state with no "carry-over" from the previous
      fetchBundle(objectId, ['auto_cuff', 'manual_cuff', 'annotations'])
        .then(bundle => retrieveMetadata(bundle))
        .catch(error => {
          alertContext.addAlert(error.message, error.severity)
        })
    }
  }

  const createPPGChart = () => {
    const options = {
      baseTime: 0,
      paddingLeft: 75,
      series: [
        {
          name: 'PS1',
          data: ps1DataRef.current,
          lineWidth: 1,
          color: '#61c0a6',
        },
        {
          name: 'PS2',
          data: ps2DataRef.current,
          lineWidth: 1,
          color: '#c08761',
        },
        {
          name: 'PS3',
          data: ps3DataRef.current,
          lineWidth: 1,
          color: '#af5ebc',
        },
      ],
      realTime: false,
      tooltip: true,
      plugins: {
        events: new EventsPlugin(eventDataRef.current),
        TimeChartZoomPlugin: new SharedZoomChartPlugin(
          {
            x: {
              autoRange: true,
            },
            y: {
              autoRange: true,
            },
          },
          setXDomain,
          setPPGYRange
        ),
      },
      xRange: xDomain,
      yRange: ppgYRange,
      xScaleType: scaleLinear,
      renderPaddingLeft: 80,
    }
    ppgChart.current = new TimeChart(ppgChartDiv.current, options)
  }

  const createAccelChart = () => {
    const options = {
      baseTime: 0,
      paddingLeft: 75,
      series: [
        {
          name: 'X',
          data: accelXDataRef.current,
          lineWidth: 1,
          color: '#8d2828',
        },
        {
          name: 'Y',
          data: accelYDataRef.current,
          lineWidth: 1,
          color: '#197519',
        },
        {
          name: 'Z',
          data: accelZDataRef.current,
          lineWidth: 1,
          color: '#353598',
        },
      ],
      realTime: false,
      tooltip: true,
      plugins: {
        TimeChartZoomPlugin: new SharedZoomChartPlugin(
          {
            x: {
              autoRange: true,
            },
            y: {
              autoRange: true,
            },
          },
          setXDomain,
          setAccelYRange
        ),
      },
      xRange: xDomain,
      yRange: accelYRange,
      xScaleType: scaleLinear,
      renderPaddingLeft: 80,
    }

    accelChart.current = new TimeChart(accelChartDiv.current, options)
  }

  useEffect(() => {
    if (ppgChart.current?.update !== undefined && !ppgChart.current?.disposed) {
      ppgChart.current.options.xRange = xDomain
      ppgChart.current.update()
    }
    if (accelChart.current?.update !== undefined && !accelChart.current?.disposed) {
      accelChart.current.options.xRange = xDomain
      accelChart.current.update()
    }
  }, [xDomain])

  useEffect(() => {
    if (isDataStreamPlotOpen) {
      setXDomain('auto')
      setPPGYRange('auto')
      setAccelYRange('auto')
      createPPGChart()
      createAccelChart()
    } else {
      setIsDataStreamPlotEnabled(false)
    }
  }, [isDataStreamPlotOpen])

  useEffect(() => {
    loadSession(objectId)
  }, [objectId])

  useEffect(() => {
    if (objectId && initialized && keycloak.authenticated) {
      resetDataStreamChart()
      if (selectedDeviceId) {
        fetchConnectionHealthPlot(_.get(selectedSessionMetadata, 'object_id'), selectedDeviceId)
      }
      fetchBundle(objectId, ['dut'])
        .then((bundle: JSZip) => retrieveSeriesData(bundle))
        .catch(error => {
          alertContext.addAlert(error.message, error.severity)
        })
    }
  }, [selectedDeviceId, objectId])

  const context: SessionEditorContextType = {
    connectionHealthPlotLoaded,
    connectionHealthPlotError,
    connectionHealthPlotURL,
    isConnectionHealthPlotOpen,
    setIsConnectionHealthPlotOpen,
    selectedSessionMetadata,
    setSelectedSessionMetadata,
    originalSessionMetadata,
    setOriginalSessionMetadata,
    handleOnEdit,
    isMetadataDirty,
    setIsMetadataDirty,
    isDisabled,
    setIsDisabled,
    isDownloaded,
    setIsDownloaded,
    isDataStreamPlotOpen,
    setIsDataStreamPlotOpen,
    isDataStreamPlotReady,
    setIsDataStreamPlotReady,
    isDataStreamPlotEnabled,
    setIsDataStreamPlotEnabled,
    selectedDeviceId,
    setSelectedDeviceId,
    loadSession,
    ps1DataRef,
    ps2DataRef,
    ps3DataRef,
    eventDataRef,
    accelXDataRef,
    accelYDataRef,
    accelZDataRef,
    xDomain,
    ppgYRange,
    accelYRange,
    setXDomain,
    setPPGYRange,
    setAccelYRange,
    ppgChartDiv,
    accelChartDiv, // TODO: Order these so they look pretty before u commit lel
  }

  return (
    objectId && (
      <SessionEditorProvider value={context}>
        <SessionEditorTabs disabled={isDisabled} objectId={objectId} />
        <Divider width={'90%'} />
        <SessionEditorPersistentForm />
        <PPGPlot
          isOpen={isDataStreamPlotOpen}
          onClose={() => {
            setIsDataStreamPlotOpen(false)
          }}
        />
        <ConnectionHealthPlot
          isOpen={isConnectionHealthPlotOpen}
          onClose={() => {
            setIsConnectionHealthPlotOpen(false)
          }}
        />
      </SessionEditorProvider>
    )
  )
}

export default SessionEditor
