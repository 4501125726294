import axios from 'axios'

const http = axios.create({
  baseURL: `${process.env.VALENCELL_API_ENDPOINT}/ingestion/v1`,
  headers: {
    'Content-Type': 'application/zip',
  },
})

class UploadFilesService {
  upload(file, onUploadProgress) {
    return http.post('/session', file, {
      onUploadProgress,
      headers: {
        'Content-Type': 'application/zip',
      },
    })
  }
}

export default new UploadFilesService()
