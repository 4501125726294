import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm,
  Divider,
  Form,
  SimpleList,
  SimpleListItem,
  Spinner,
  spinnerSize,
  Text,
} from '@patternfly/react-core'
import _ from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import {ErrorCircleOIcon} from '@patternfly/react-icons'
import {useSessionEditor} from '@app/Components/SessionEditor/SessionEditorContext'

type IDeviceMetadataProps = {
  metadata: any
}

export const DeviceMetadata: React.FunctionComponent<IDeviceMetadataProps> = ({metadata}) => {
  const sessionEditor = useSessionEditor()

  const onSelect = (selectedItem, selectedItemProps) => {
    sessionEditor.setSelectedDeviceId(selectedItemProps.componentProps.device_id)
  }

  const deviceCard = () => {
    const dutMetaData = _.get(metadata, ['source', 'dut', sessionEditor.selectedDeviceId])
    const isDut = dutMetaData != null
    return (
      <React.Fragment>
        <Divider />
        <DescriptionList
          columnModifier={{
            default: '1Col',
          }}
          isHorizontal>
          <DescriptionListGroup>
            <DescriptionListTerm>Device ID</DescriptionListTerm>
            <DescriptionListDescription>{sessionEditor.selectedDeviceId}</DescriptionListDescription>
          </DescriptionListGroup>
          {isDut && (
            <React.Fragment>
              <DescriptionListGroup>
                <DescriptionListTerm>Firmware Revision</DescriptionListTerm>
                <DescriptionListDescription>{_.get(dutMetaData, 'firmware_revision', '')}</DescriptionListDescription>
              </DescriptionListGroup>
              <DescriptionListGroup>
                <DescriptionListTerm>LED 0 Drive Level</DescriptionListTerm>
                <DescriptionListDescription>{_.get(dutMetaData, 'initial_config.OPT_CONFIG_0.LED0_DRIVE_LEVEL', '')}</DescriptionListDescription>
              </DescriptionListGroup>
              <DescriptionListGroup>
                <DescriptionListTerm>LED 1 Drive Level</DescriptionListTerm>
                <DescriptionListDescription>{_.get(dutMetaData, 'initial_config.OPT_CONFIG_0.LED1_DRIVE_LEVEL', '')}</DescriptionListDescription>
              </DescriptionListGroup>
              <DescriptionListGroup>
                <DescriptionListTerm>LED 2 Drive Level</DescriptionListTerm>
                <DescriptionListDescription>{_.get(dutMetaData, 'initial_config.OPT_CONFIG_1.LED2_DRIVE_LEVEL', '')}</DescriptionListDescription>
              </DescriptionListGroup>
              {sessionEditor.isDataStreamPlotEnabled && (
                <Button key='create' variant='secondary' isDisabled={!sessionEditor.isDataStreamPlotReady} onClick={() => sessionEditor.setIsDataStreamPlotOpen(true)}>
                  Plot DataStream {sessionEditor.isDataStreamPlotReady ? '' : sessionEditor.isDownloaded ? '(Preparing...)' : '(Downloading...)'}
                </Button>
              )}
            </React.Fragment>
          )}
          <DescriptionListGroup>
            <DescriptionListTerm>Connection Health</DescriptionListTerm>
            <DescriptionListDescription>
              {sessionEditor.connectionHealthPlotLoaded && !sessionEditor.connectionHealthPlotError && (
                <img
                  src={sessionEditor.connectionHealthPlotURL}
                  alt={'Device Connection Health Plot'}
                  onError={e => {
                    sessionEditor.setConnectionHealthPlotError(true)
                  }}
                  onClick={() => sessionEditor.setIsConnectionHealthPlotOpen(true)}
                  style={{cursor: 'pointer'}}
                />
              )}
              {sessionEditor.connectionHealthPlotError && <ErrorCircleOIcon title={'Error loading connection plot'} />}
              {!sessionEditor.connectionHealthPlotLoaded && <Spinner size={spinnerSize.md} />}
            </DescriptionListDescription>
          </DescriptionListGroup>
        </DescriptionList>
      </React.Fragment>
    )
  }

  const contents = () => {
    const deviceIds = []
    if ('dut' in _.get(metadata, 'source', {})) {
      deviceIds.push(...Object.keys(metadata.source.dut))
    }
    if ('reference' in _.get(metadata, 'source', {})) {
      deviceIds.push(...Object.keys(metadata.source.reference))
    }
    if (deviceIds.length > 0) {
      const deviceListItems = deviceIds.map(id => (
        <SimpleListItem isActive={sessionEditor.selectedDeviceId === id} key={id} componentProps={{device_id: id}}>
          {id}
        </SimpleListItem>
      ))
      return (
        <Form>
          <Card>
            <CardTitle>Devices</CardTitle>
            <CardBody>
              <SimpleList aria-label='Device List' onSelect={onSelect}>
                {deviceListItems}
              </SimpleList>
            </CardBody>
            <CardBody>{deviceIds.includes(sessionEditor.selectedDeviceId) && deviceCard()}</CardBody>
            <CardBody>{}</CardBody>
          </Card>
        </Form>
      )
    } else {
      return (
        <Card>
          <CardTitle>Devices</CardTitle>
          <CardBody>
            <Text>No devices found</Text>
          </CardBody>
        </Card>
      )
    }
  }

  return contents()
}

export default DeviceMetadata
